/* FONT */
body, h1, h2, h3, h4, h5, .ui.button, .ui.text.container, .ui.header, .ui.input input, .ui.input > input,
.ui.list .list > .item .header, .ui.list > .item .header, .ui.steps .step .title,
.ui.form input:not([type]), .ui.form input[type="date"], .ui.form input[type="datetime-local"], .ui.form input[type="email"],
.ui.form input[type="number"], .ui.form input[type="password"], .ui.form input[type="search"], .ui.form input[type="tel"], .ui.form input[type="time"],
.ui.form input[type="text"], .ui.form input[type="file"], .ui.form input[type="url"], .ui.menu, .ui.message .header,
.ui.cards > .card > .content > .header, .ui.card > .content > .header, .ui.items > .item > .content > .header,
.ui.statistics .statistic > .value, .ui.statistic > .value, .ui.statistics .statistic > .label, .ui.statistic > .label,
.ui.accordion .title:not(.ui), .ui.modal > .header, .ui.popup > .header, .ui.search > .results .result .title,
.ui.search > .results > .message .header, .ui.category.search > .results .category > .name {
  font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

/* GRID */
.ui.grid > .column:not(.row), .ui.grid > .row > .column {
  width: 8.33333333%;
}

.ui.grid > .row > .one.wide.column,
.ui.grid > .column.row > .one.wide.column,
.ui.grid > .one.wide.column,
.ui.column.grid > .one.wide.column,
.ui.form .one.wide.field {
  width: 8.33333333% !important;
}

.ui.grid > .row > .two.wide.column,
.ui.grid > .column.row > .two.wide.column,
.ui.grid > .two.wide.column,
.ui.column.grid > .two.wide.column,
.ui.form .two.wide.field {
  width: 16.66666667% !important;
}

.ui.grid > .row > .three.wide.column,
.ui.grid > .column.row > .three.wide.column,
.ui.grid > .three.wide.column,
.ui.column.grid > .three.wide.column,
.ui.form .three.wide.field {
  width: 25% !important;
}

.ui.grid > .row > .four.wide.column,
.ui.grid > .column.row > .four.wide.column,
.ui.grid > .four.wide.column,
.ui.column.grid > .four.wide.column,
.ui.form .four.wide.field {
  width: 33.33333333% !important;
}

.ui.grid > .row > .five.wide.column,
.ui.grid > .column.row > .five.wide.column,
.ui.grid > .five.wide.column,
.ui.column.grid > .five.wide.column,
.ui.form .five.wide.field {
  width: 41.66666667% !important;
}

.ui.grid > .row > .six.wide.column,
.ui.grid > .column.row > .six.wide.column,
.ui.grid > .six.wide.column,
.ui.column.grid > .six.wide.column,
.ui.form .six.wide.field {
  width: 50% !important;
}

.ui.grid > .row > .seven.wide.column,
.ui.grid > .column.row > .seven.wide.column,
.ui.grid > .seven.wide.column,
.ui.column.grid > .seven.wide.column,
.ui.form .seven.wide.field {
  width: 58.33333333% !important;
}

.ui.grid > .row > .eight.wide.column,
.ui.grid > .column.row > .eight.wide.column,
.ui.grid > .eight.wide.column,
.ui.column.grid > .eight.wide.column,
.ui.form .eight.wide.field {
  width: 66.66666667% !important;
}

.ui.grid > .row > .nine.wide.column,
.ui.grid > .column.row > .nine.wide.column,
.ui.grid > .nine.wide.column,
.ui.column.grid > .nine.wide.column,
.ui.form .nine.wide.field {
  width: 75% !important;
}

.ui.grid > .row > .ten.wide.column,
.ui.grid > .column.row > .ten.wide.column,
.ui.grid > .ten.wide.column,
.ui.column.grid > .ten.wide.column,
.ui.form .ten.wide.field {
  width: 83.33333333% !important;
}

.ui.grid > .row > .eleven.wide.column,
.ui.grid > .column.row > .eleven.wide.column,
.ui.grid > .eleven.wide.column,
.ui.column.grid > .eleven.wide.column,
.ui.form .eleven.wide.field {
  width: 91.66666667% !important;
}

.ui.grid > .row > .twelve.wide.column,
.ui.grid > .column.row > .twelve.wide.column,
.ui.grid > .twelve.wide.column,
.ui.column.grid > .twelve.wide.column,
.ui.form .twelve.wide.field {
  width: 100% !important;
}

.ui.grid > .row > .thirteen.wide.column,
.ui.grid > .column.row > .thirteen.wide.column,
.ui.grid > .thirteen.wide.column,
.ui.column.grid > .thirteen.wide.column,
.ui.form .thirteen.wide.field {
  width: 108.33333333% !important;
}

.ui.grid > .row > .fourteen.wide.column,
.ui.grid > .column.row > .fourteen.wide.column,
.ui.grid > .fourteen.wide.column,
.ui.column.grid > .fourteen.wide.column,
.ui.form .fourteen.wide.field {
  width: 116.66666667% !important;
}

.ui.grid > .row > .fifteen.wide.column,
.ui.grid > .column.row > .fifteen.wide.column,
.ui.grid > .fifteen.wide.column,
.ui.column.grid > .fifteen.wide.column,
.ui.form .fifteen.wide.field {
  width: 125% !important;
}

.ui.grid > .row > .sixteen.wide.column,
.ui.grid > .column.row > .sixteen.wide.column,
.ui.grid > .sixteen.wide.column,
.ui.column.grid > .sixteen.wide.column,
.ui.form .sixteen.wide.field {
  width: 133.33333333% !important;
}

@media only screen and (max-width: 575px) {
  .ui.form.xs-auto .ui.grid>.stackable.stackable.row>.column, .ui.form.xs-auto .ui.stackable.grid>.column.grid>.column, .ui.form.xs-auto .ui.stackable.grid>.column.row>.column, 
  .ui.form.xs-auto .ui.stackable.grid>.column:not(.row), .ui.stackable.grid>.row>.column, .ui.form.xs-auto .ui.stackable.grid>.row>.wide.column, .ui.form.xs-auto .ui.stackable.grid>.wide.column {
    width: 100%!important;
  }
}

/* Mobile Sizing Combinations */
@media only screen and (max-width: 767px) {
  .ui.form.xs-auto .ui.stackable.grid > .row > .one.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .one.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .one.wide.column.mobile {
    width: 8.33333333% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .two.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .two.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .two.wide.column.mobile {
    width: 16.66666667% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .three.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .three.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .three.wide.column.mobile {
    width: 25% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .four.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .four.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .four.wide.column.mobile {
    width: 33.33333333% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .five.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .five.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .five.wide.column.mobile {
    width: 41.66666667% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .six.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .six.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .six.wide.column.mobile {
    width: 50% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .seven.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .seven.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .seven.wide.column.mobile {
    width: 58.33333333% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .eight.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .eight.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .eight.wide.column.mobile {
    width: 66.66666667% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .nine.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .nine.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .nine.wide.column.mobile {
    width: 75% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .ten.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .ten.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .ten.wide.column.mobile {
    width: 83.33333333% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .eleven.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .eleven.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .eleven.wide.column.mobile {
    width: 91.66666667% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .twelve.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .twelve.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .twelve.wide.column.mobile {
    width: 100% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .thirteen.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .thirteen.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .thirteen.wide.column.mobile {
    width: 108.33333333% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .fourteen.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .fourteen.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .fourteen.wide.column.mobile {
    width: 116.66666667% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .fifteen.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .fifteen.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .fifteen.wide.column.mobile {
    width: 125% !important;
  }

  .ui.form.xs-auto .ui.stackable.grid > .row > .sixteen.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .column.row > .sixteen.wide.column.mobile,
  .ui.form.xs-auto .ui.stackable.grid > .sixteen.wide.column.mobile {
    width: 133.33333333% !important;
  }
}