.rdt, .ui.table .rdt {
  position: relative;
}
.rdtPicker, .ui.table .rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker, .ui.table .rdtOpen .rdtPicker {
  display: block;
}

.rdtOpen .rdtPicker.toLeft, .ui.table .rdtOpen .rdtPicker.toLeft {
  right: 0px;
}

.rdtStatic .rdtPicker, .ui.table .rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}
.rdtPicker .rdtTimeToggle, .ui.table .rdtPicker .rdtTimeToggle {
  text-align: center;
}
.rdtPicker table, .ui.table .rdtPicker table {
  width: 100%;
  margin: 0;
}
.ui.striped.table .rdtPicker tbody tr:nth-child(2n), .ui.striped.table .rdtPicker tbody tr:hover, .rdtPicker .ui.selectable.table tbody tr:hover,
.ui.table .rdtPicker thead th {
  background-color: transparent!important;
}
.ui.table thead th .rdtPicker {
  font-weight: normal;
}
.rdtPicker td, .rdtPicker th,
.ui.table .rdtPicker td, .ui.table .rdtPicker th {
  text-align: center;
  height: 28px;
  padding: 1px;
  border: none!important;
}
.rdtPicker td, .ui.table .rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover, .ui.table .rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover, .ui.table .rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover, .ui.table .rdtPicker td.rdtMinute:hover, 
.rdtPicker td.rdtSecond:hover, .ui.table .rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover, .ui.table .rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld, .ui.table .rdtPicker td.rdtOld,
.rdtPicker td.rdtNew, .ui.table .rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday, .ui.table .rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before, .ui.table .rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive, .ui.table .rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover, .ui.table .rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before, .ui.table .rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled, .ui.table .rdtPicker td.rdtDisabled
.rdtPicker td.rdtDisabled:hover, .ui.table .rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker td span.rdtOld, .ui.table .rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled, .ui.table .rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover, .ui.table .rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th, .ui.table .rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow, .ui.table .rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch, .ui.table .rdtPicker th.rdtSwitch {
  width: 100px;
}

.rdtPicker th.rdtNext, .ui.table .rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev, .ui.table .rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}
.rdtPrev span, .ui.table .rdtPrev span,
.rdtNext span, .ui.table .rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtPicker th.rdtDisabled, .ui.table .rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover, .ui.table .rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th, .ui.table .rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover, .ui.table .rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}
.rdtPicker tfoot, .ui.table .rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}
.rdtPicker button, .ui.table .rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover, .ui.table .rdtPicker button:hover {
  background-color: #eee;
}
.rdtPicker thead button, .ui.table .rdtPicker thead button {
  width: 100%;
  height: 100%;
}
td.rdtMonth, .ui.table td.rdtMonth,
td.rdtYear, .ui.table td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover, .ui.table td.rdtMonth:hover,
td.rdtYear:hover, .ui.table td.rdtYear:hover {
  background: #eee;
}
.rdtCounters, .ui.table .rdtCounters {
  display: inline-block;
}
.rdtCounters > div, .ui.table .rdtCounters > div {
  float: left;
}
.rdtCounter, .ui.table .rdtCounter {
  height: 100px;
}
.rdtCounter, .ui.table .rdtCounter {
  width: 40px;
}
.rdtCounterSeparator, .ui.table .rdtCounterSeparator {
  line-height: 100px;
}
.rdtCounter .rdtBtn, .ui.table .rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover, .ui.table .rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount, .ui.table .rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}
.rdtMilli, .ui.table .rdtMilli {
  padding-top: 30px;
  padding-left: 5px;
  width: 60px;
}
.rdtMilli input, .ui.table .rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}
.rdtTime td, .ui.table .rdtTime td {
  cursor: default;
}