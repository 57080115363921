#modelerContainer {
  border: 2px solid #ccc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 100vw;
  height: 100vh;
}

#modelerContainer li a {
  font-size: 8pt;
  color: #024D86;
  font-family: Verdana, Arial, sans-serif;
  line-height: 0;
}

#modelerContainer .ui.table thead th div.inline-div .ui.input.editable-label input, #modelerContainer .inline.field .ui.input.editable-label input {
  min-width: 120px;
}

.formModelerTinge, .ui.tabular.menu .active.item.formModelerTinge, .ui.tabular.menu .item.formModelerTinge, .ui.tabular.menu .item.formModelerTinge:hover   {
    background: rgba(242, 242, 242, 1);
}

.formModelerTingeContainer {
    background: rgba(254, 247, 241, 1);
}

td .formModelerTinge, td .formModelerPlaceholder {
  width: 100%;
}
.formModelerSelected, .ui.tabular.menu .active.item.formModelerSelected, .ui.tabular.menu .item.formModelerSelected, .ui.tabular.menu .item.formModelerTinge:hover   {
    background: rgba(63, 151, 147, 0.4);
}
.fm-new-line {
    background: rgba(63, 151, 147, 0.2);
}
.formModelerPlaceholder, .ui.tabular.menu .active.item.formModelerPlaceholder, .ui.tabular.menu .item.formModelerPlaceholder  {
    background: rgba(0, 0, 0, 0.2);
    /*opacity: 0;*/
}
/*
.field.emphasize button, .field.emphasize a {
  pointer-events: none;
}*/

.formModelerPlaceholder * {
  visibility: hidden;
}
.interacted {
    background: rgba(63, 151, 147, 0.4);
    /*opacity: 0.4;*/
}
#formModelerInteractiveField {
  margin: 0px;
}
.ui.form .formModelerStructuralMode,
.ui.grid>.column:not(.row).formModelerStructuralMode,
.ui.grid>.row>.column.formModelerStructuralMode {
  min-height: 25px;
  min-width: 25px;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-top: 5px;
  margin-left:  5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border:  1px solid rgba(95, 97, 115, 0.8);
  color: rgb(95, 97, 115);
}

.ui.form .formModelerStructuralMode input[type="text"], .ui.form .formModelerStructuralMode textarea {
  background-color : rgb(253, 253, 253);
  border: 1px solid rgb(129, 131, 143); 
}

.modelerContainer .ui.form .formModelerStructuralMode .help {
  min-height: 1px;
}

.modelerContainer div.space20 {
  display: none;
}

.ui.form .formModelerStructuralModeContainer.formModelerStructuralMode, 
.ui.grid>.column:not(.row).formModelerStructuralModeContainer.formModelerStructuralMode,
.ui.grid>.row>.column.formModelerStructuralModeContainer.formModelerStructuralMode {
  border:  1px solid rgba(189,152,125,1);
  color: rgba(175,93,107,1);
}

.modelerContainer .form .ui.grid {
  margin: 0;
}

.formModelerStructuralMode .ui.grid>.row {
  padding-top: 0;
  padding-bottom: 0;
}

.ui.read-only.checkbox.formModelerStructuralMode, 
.ui.read-only.checkbox.formModelerStructuralMode label {
  cursor: unset;
}

.ui.form .formModelerStructuralMode.newLineAfter,
.ui.grid>.column:not(.row).formModelerStructuralMode.newLineAfter,
.ui.grid>.row>.column.formModelerStructuralMode.newLineAfter,
.ui.form .formModelerStructuralModeContainer.formModelerStructuralMode.newLineAfter, 
.ui.grid>.column:not(.row).formModelerStructuralModeContainer.formModelerStructuralMode.newLineAfter,
.ui.grid>.row>.column.formModelerStructuralModeContainer.formModelerStructuralMode.newLineAfter {
  border-right-width: 2px;
}

.ui.form .formModelerStructuralMode.newLineBefore,
.ui.grid>.column:not(.row).formModelerStructuralMode.newLineBefore,
.ui.grid>.row>.column.formModelerStructuralMode.newLineBefore,
.ui.form .formModelerStructuralModeContainer.formModelerStructuralMode.newLineBefore, 
.ui.grid>.column:not(.row).formModelerStructuralModeContainer.formModelerStructuralMode.newLineBefore,
.ui.grid>.row>.column.formModelerStructuralModeContainer.formModelerStructuralMode.newLineBefore {
  border-left-width: 2px;
}

.ui.form .one.wide.field.formModelerStructuralMode,
.ui.grid > .row > [class*="one wide"].column.formModelerStructuralMode,
.ui.grid > .column.row > [class*="one wide"].column.formModelerStructuralMode,
.ui.grid > [class*="one wide"].column.formModelerStructuralMode,
.ui.column.grid > [class*="one wide"].column.formModelerStructuralMode {
  width: calc(8.33333333% - 10px) !important;
}

.ui.form .two.wide.field.formModelerStructuralMode,
.ui.grid > .row > [class*="two wide"].column.formModelerStructuralMode,
.ui.grid > .column.row > [class*="two wide"].column.formModelerStructuralMode,
.ui.grid > [class*="two wide"].column.formModelerStructuralMode,
.ui.column.grid > [class*="two wide"].column.formModelerStructuralMode {
  width: calc(16.66666667% - 10px) !important;
}

.ui.form .three.wide.field.formModelerStructuralMode,
.ui.grid > .row > [class*="three wide"].column.formModelerStructuralMode,
.ui.grid > .column.row > [class*="three wide"].column.formModelerStructuralMode,
.ui.grid > [class*="three wide"].column.formModelerStructuralMode,
.ui.column.grid > [class*="three wide"].column.formModelerStructuralMode {
  width: calc(25% - 10px) !important;
}

.ui.form .four.wide.field.formModelerStructuralMode,
.ui.grid > .row > [class*="four wide"].column.formModelerStructuralMode,
.ui.grid > .column.row > [class*="four wide"].column.formModelerStructuralMode,
.ui.grid > [class*="four wide"].column.formModelerStructuralMode,
.ui.column.grid > [class*="four wide"].column.formModelerStructuralMode {
  width: calc(33.33333333% - 10px) !important;
}

.ui.form .five.wide.field.formModelerStructuralMode,
.ui.grid > .row > [class*="five wide"].column.formModelerStructuralMode,
.ui.grid > .column.row > [class*="five wide"].column.formModelerStructuralMode,
.ui.grid > [class*="five wide"].column.formModelerStructuralMode,
.ui.column.grid > [class*="five wide"].column.formModelerStructuralMode {
  width: calc(41.66666667% - 10px) !important;
}

.ui.form .six.wide.field.formModelerStructuralMode,
.ui.grid > .row > [class*="six wide"].column.formModelerStructuralMode,
.ui.grid > .column.row > [class*="six wide"].column.formModelerStructuralMode,
.ui.grid > [class*="six wide"].column.formModelerStructuralMode,
.ui.column.grid > [class*="six wide"].column.formModelerStructuralMode {
  width: calc(50% - 10px) !important;
}

.ui.form .seven.wide.field.formModelerStructuralMode,
.ui.grid > .row > [class*="seven wide"].column.formModelerStructuralMode,
.ui.grid > .column.row > [class*="seven wide"].column.formModelerStructuralMode,
.ui.grid > [class*="seven wide"].column.formModelerStructuralMode,
.ui.column.grid > [class*="seven wide"].column.formModelerStructuralMode {
  width: calc(58.33333333% - 10px) !important;
}

.ui.form .eight.wide.field.formModelerStructuralMode,
.ui.grid > .row > [class*="eight wide"].column.formModelerStructuralMode,
.ui.grid > .column.row > [class*="eight wide"].column.formModelerStructuralMode,
.ui.grid > [class*="eight wide"].column.formModelerStructuralMode,
.ui.column.grid > [class*="eight wide"].column.formModelerStructuralMode {
  width: calc(66.66666667% - 10px) !important;
}

.ui.form .nine.wide.field.formModelerStructuralMode,
.ui.grid > .row > [class*="nine wide"].column.formModelerStructuralMode,
.ui.grid > .column.row > [class*="nine wide"].column.formModelerStructuralMode,
.ui.grid > [class*="nine wide"].column.formModelerStructuralMode,
.ui.column.grid > [class*="nine wide"].column.formModelerStructuralMode {
  width: calc(75% - 10px) !important;
}

.ui.form .ten.wide.field.formModelerStructuralMode,
.ui.grid > .row > [class*="ten wide"].column.formModelerStructuralMode,
.ui.grid > .column.row > [class*="ten wide"].column.formModelerStructuralMode,
.ui.grid > [class*="ten wide"].column.formModelerStructuralMode,
.ui.column.grid > [class*="ten wide"].column.formModelerStructuralMode {
  width: calc(83.33333333% - 10px) !important;
}

.ui.form .eleven.wide.field.formModelerStructuralMode,
.ui.grid > .row > [class*="eleven wide"].column.formModelerStructuralMode,
.ui.grid > .column.row > [class*="eleven wide"].column.formModelerStructuralMode,
.ui.grid > [class*="eleven wide"].column.formModelerStructuralMode,
.ui.column.grid > [class*="eleven wide"].column.formModelerStructuralMode {
  width: calc(91.66666667% - 10px) !important;
}

.ui.form .twelve.wide.field.formModelerStructuralMode,
.ui.grid > .row > [class*="twelve wide"].column.formModelerStructuralMode,
.ui.grid > .column.row > [class*="twelve wide"].column.formModelerStructuralMode,
.ui.grid > [class*="twelve wide"].column.formModelerStructuralMode,
.ui.column.grid > [class*="twelve wide"].column.formModelerStructuralMode {
  width: calc(100% - 10px) !important;
}


#palette .column:not(.row).formModelerStructuralMode, #palette .ui.grid.segment {
  margin: 0px;
  padding-top: 0px;
  width: 200px;
  padding-bottom: 2px;
}

.modelerContainer .idLabel {
  font-size: 10px;
  line-height: 85%;
}

#modeler {
  /*overflow: scroll;*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}




#inspector .inner .dropdown {
  padding: .367861429em 1em;
  min-height: 0px;
}

#inspector .inner  .input input, #inspector .inner  .dropdown .text {
  font-size: 9.42857px;
}

#inspector .checkbox {
  min-height: 12px;
}

#inspector .dropdown .item{
  font-size: 12px;
}

#palette {
    position: absolute;
    width: 808px;
    z-index: 100;
    background: #FFF;
    border: 1px solid rgba(34,36,38,.15);
}

#palette .inner {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 540px;
  padding: 5px;
}

#palette .field {
  padding: 5px;
  margin-right: 2px;
}

#palette .field > label {
  display: none;
}

#emptyArea {
  height: 50vh;
}

#modeler .modal {
  z-index: 2;
  position: fixed;
  display: block; /* Hidden by default */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
}


#inspector {
  position: absolute;
  z-index: 100;
  background: #FFF;
  border: 1px solid rgba(34,36,38,.15);
}

#inspector .inner {
  padding: 5px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 700px;
}

#inspector .inner.large {
  flex-direction: column;
  flex-wrap: wrap;
}


#inspector .inner .group-name {
  font-weight: bold;
}
#inspector .inner .option {
  padding-top: 3px;
  margin-right: 5px;
  flex-shrink: 0;
}

#inspector .inner .option.warning {
  color: red;
}

#inspector .inner .option:nth-child(2n) {
  background-color: rgba(0,0,50,.05);
}

#inspector .inner .group-name:not(:first-child) {
  padding-top: 10px;
}
#inspector .inner .ui.input input {
  padding: .367861429em 1em;
}

#inspector .inner .option .name, #inspector .inner .option .value{
  width: 200px;
}

#inspector .inner .option .help {
  font-size: 10px;
}
