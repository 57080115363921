#content {
  padding: 20px; 
}

:focus {
  outline: none;
}

.mnc-debugbuttons {
  position: fixed;
  z-index: 100000;
  background-color: #CEEAFF;
  padding: 5px;
  border: 2px solid #2185d0;
}

.mnc-debugbuttons.left {
  top: 3px;
  left: 3px;
  padding-right: 40px;
}

.mnc-debugbuttons.right {
  top: 3px;
  right: 3px;
  padding-left: 40px;
}

.mnc-debugbuttons .ui[class*="top right attached"].label, .mnc-debugbuttons .ui[class*="top left attached"].label {
  border-radius: 0px;
  padding: 3px;
  padding-left: 12px;
}

.mnc-debugbuttons .ui.top.attached.label:first-child+:not(.attached) {
  margin-top: 0px!important;
}
 
.exceptionEnd {
  background-color: #FFBFBF;
  padding: 20px;
  margin: auto;
  margin-top: 10px;
  border: 1px solid red;
  color: red;
}

.outputEnd {
  background-color: #E0FCE4;
  padding: 20px;
  margin: auto;
  margin-top: 10px;
  border: 1px solid #416646;
}

.curpointer {
  cursor: pointer;
}

.curgrab {
  cursor: grab;
}

.inline-div {
  display: inline-block;
}

.ui.form .field label.warning, .ui.form .field label.error, .ui.form .field label.help {
  min-height: 24px;
}

.ui.form .field label.help.thin {
  min-height: 5px;
}

.ui.form .field label.error {
  color: red;
  font-weight: normal;
  margin: 0px;
}

.ui.form .field.warning .input, .ui.form .field.warning label {
  color: #B77B14;
}

.ui.form .field label.warning {
  color: orange;
  font-weight: normal;
  margin: 0px;
  vertical-align: top;
}

.ui.form .field label.warning .ui.checkbox label:before {
  margin: 3px;
  width: 14px;
  height: 14px;
}

.ui.form .field label.warning span.text {
  position: relative;
  top: -2px;
  margin-left: 3px;
}

.ui.form .field label.help {
  color: #BCBCBC;
  font-weight: normal;
  margin: 0px;
}

.ui.form .field.widget-pageabletable>label.editable, .ui.form .field.widget-table>label.editable {
  padding-left: 1rem;
}

.ui.form .field.inline label.editable {
  margin-right: 10px;
}

.ui.form .field.inline label.help {
  margin-left: 10px;
  display: block;
}

.ui.form .inline.field>label.editable {
  vertical-align: top;
}

.ui.form .inline.field:not(.widget-checkbox):not(.widget-icon):not(.widget-label)::not(.widget-radiobutton):not(.widget-link):not(.textmode)>label.editable {
  margin-top: 9px;
}

.ui.form .inline.field.widget-radiogroup>label.editable {
  margin-top: 15px!important;
}

.ui.form .inline.field.widget-slider>label.editable {
  margin-top: 13px!important;
}

.ui.form .inline.wide.field>.input>input, .ui.form .inline.wide.field>.input>.input>input {
  display: inline-block;
  width: auto;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: middle;
  font-size: 1em;
}

.ui.form .field input.error, .ui.form .field select.error, .ui.form .field select.textarea {
  border-color: red;
}

.widget-radiogroup .ui.grid.buttons {
  margin-left: 0px;
  margin-right: 0px;
}

.ui.buttons .widget-radiobutton.button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ui.buttons .widget-radiobutton.button:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ui.buttons .widget-radiobutton.button.last-right {
  border-top-right-radius: .28571429rem;
  border-bottom-right-radius: .28571429rem;
}

.ui.buttons .widget-radiobutton.button.first-left {
  border-top-left-radius: .28571429rem;
  border-bottom-left-radius: .28571429rem;
}

.ui.column.field.widget.widget-radiobutton.ui.button {
  padding: 0px!important;
}

.ui.column.field.widget.widget-radiobutton.ui.button .ui.checkbox.radio {
  padding: .78571429em 1.5em .78571429em;
  padding-top: 15px;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}

.ui.column.field.widget.widget-radiobutton.ui.button .ui.radio.checkbox label {
  padding-left: 0px;
}

.ui.column.field.widget.widget-radiobutton.ui.button .ui.radio.checkbox label:before, .ui.column.field.widget.widget-radiobutton.ui.button .ui.radio.checkbox label:after {
  display: none;
}

.ui.form .field.warning input:not([type]), .ui.form .field.warning input[type=text], .ui.form .field.warning textarea {
  background: #FFF9F2;
  border-color: #F7D8AA;
  color: #B77B14;
  -webkit-box-shadow: none;
  box-shadow: none;
}

textarea.nowrap {
  white-space: pre;
  overflow: auto;
}

.ui.form .upload-file-name {
  padding-left: 10px;
}

.ui.form .upload-file-name .progress {
  color: green;
}

.ui.row.mc-label {
  padding-left: 14px;
  margin-bottom: 10px;
}

span.rstar {
  color: red;
  font-weight: bold;
}

div.space20 {
  height: 20px;
}

.ui.loader {
  top: 50px;
}

.ui.table:last-child {
  margin-bottom: 1em;
}

.ui.table td, .ui.table th {
  position: relative;
}

.ui.sortable.table thead th.sortable:after {
  content: '\f0dc';
}

.ui.sortable.table thead th.sorted {
  background: #f9fafb;
}

.ui.sortable.table thead th.sorted:hover, .ui.sortable.table thead th:hover {
  background: #f4f4f4;
}

.ui.sortable.table thead th.sorted.ascending:after, .ui.sortable.table thead th.sorted.descending:after {
  color: red;
}

.ui.sortable.table thead th {
  white-space: normal;
}

.ui.fixed.table td, .ui.fixed.table th {
  overflow: visible;
  text-overflow: clip;
}

td.ellipsis div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

td.nowrap div, th.nowrap {
  white-space: nowrap;
}

.ui.table td.no-row-column {
  text-align: center;
}

.ui.table th.selector, .ui.table td.selector {
  text-align: left;
  white-space: nowrap;
}

.ui.table tr.selected {
  background-color: #CDECF7;
}

.ui.table thead th.group, .ui.sortable.table thead th.group {
  cursor: auto;
}

.ui.table thead th.group-empty, .ui.sortable.table thead th.group-empty {
  cursor: auto;
  background: transparent;
}  

.ui.table thead th.group:hover, .ui.sortable.table thead th.group:hover {
  background: #f9fafb;
}

.ui.table.sticky-header thead { 
  position: sticky;
  top: 0; 
  z-index: 10;
}

.table th .resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
  height: 100%;
}

.table th .resizer:hover, .table th .resizing {
    border-right: 2px solid blue;
}

.hbox .gapped:not(:first-child) {
  margin-left: 10px;
}

.vbox .gapped:not(:first-child) {
  margin-top: 10px;
}

.hbox button + label.help {
  display: none;
}

.hbox .ui.button {
  margin-right: 0px;
}

button, button:focus, button:active {
  outline: none;
}

.ripper-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-right: 0.25em;
}

.ripper {
  position: absolute;
  width: 0px;
  height: 1px;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  background-color: rgba(236, 240, 241, .3);
  cursor: pointer;
}

.ripper-wrapper .ui.button {
  margin-right: 0px;
}

.ui.grid {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ui.grid>.row {
  padding-top: 0px;
  padding-bottom: 0px;
}

@media only screen and (max-width: 767px) {
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.grid > .stackable.stackable.row > .column {
    padding: 3px 1rem !important;
  }
}

.ui.segment {
  padding-top: 13px;
  padding-bottom: 13px;
}

.ui.column.segment:not(.inverted):not(.secondary):not(.tertiary), .ui.column.field.segment:not(.inverted):not(.secondary):not(.tertiary)  {
  background-color: #fff!important;
  color: rgba(0,0,0,.87)
}

.ui.grid>.row>.black.column.segment, .ui.grid>.row>.blue.column.segment, .ui.grid>.row>.brown.column.segment, .ui.grid>.row>.green.column.segment, .ui.grid>.row>.grey.column.segment, 
.ui.grid>.row>.olive.column.segment, .ui.grid>.row>.orange.column.segment, .ui.grid>.row>.pink.column.segment, .ui.grid>.row>.purple.column.segment, .ui.grid>.row>.red.column.segment, 
.ui.grid>.row>.teal.column.segment, .ui.grid>.row>.violet.column.segment, .ui.grid>.row>.yellow.column.segment {
  margin-top: 0px;
  margin-bottom: 0px;
}  

.ui.checkbox label.error:before, .ui.checkbox label.help:before {
  display: none;
}


.ui.checkbox input~label.error:after, .ui.checkbox input~label.help:after {
  display: none;
}

.mnc.tabpanel .ui.segment:last-child {
  margin-bottom: 1rem;
}

.mnc.tabpanel>.ui.menu .item a {
  color: rgba(0,0,0,.87);
}

.mnc.tabpanel>.ui.menu .item>i.icon:last-child {
  margin: 0 0 0 .35714286em;
}

.mnc.error, .mnc.red, .ui.form .mnc.red, .ui.form .mnc.error {
  color: red;
}

.mnc.green, .ui.form .mnc.green {
  color: green;
}

.mnc.blue, .ui.form .mnc.blue  {
  color: blue;
}

.mnc.clickable.icon:not(.disabled) {
  cursor: pointer;
}

.ui.form .flex-row {
  display: flex;
  flex-flow: row wrap;
}

.ui.form .flex-column {
  display: flex;
  flex-flow: column wrap;
}

.ui.form .flex-grow {
  flex-grow: 1;
}

.ui.form .flex-middle {
  align-items: center;
}

.ui.form .flex-center {
  justify-content: center;
}

.ui.form .flex-right {
  justify-content: flex-end;
}

.ui.form .flex-no-wrap {
  flex-wrap: nowrap;
}

.ui.form .field.wide.inline > div, .ui.form .field.wide.inline > div.ui.input {
  display: inline-block;
  width: auto;
}

.mnc-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  padding: 50px;
  z-index: 2000;
  overflow: auto;
}

.mnc-backdrop.context {
  background-color: transparent;
}

@media only screen and (max-width: 575px) {
  .mnc-backdrop {
    padding: 5px;
  }
}

.mnc-modal {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 auto;
  padding: 30px;
  z-index: 2001;
  font-size: 1rem;
}

.mnc-modal.context {
  position: absolute;
  top: 30px;
  left: 30px;
  padding: 10px;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  border: 1px solid rgba(34,36,38,.15);
}

.showing-modal {
  overflow: hidden;
}

.mnc-modal .close.icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.mnc-modal > .header {
  display: block;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 1.4em;
  background: #FFFFFF;
  margin: 0em;
  padding: 0 1.5rem 1rem 1.5rem;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.mnc-modal > .content {
  display: block;
  width: 100%;
  font-size: 1em;
  line-height: 1.4;
  padding: 1.5rem;
  background: #FFFFFF;
}

.mnc-modal .actions {
  text-align: right;
}

/* Modal responsive */
@media only screen and (max-width: 767px) {
  .mnc-modal {
    width: 95%;
  }
  .mnc-modal.context {
    width: auto;
  }
  .mnc-modal.mini {
    width: 95%;
  }
  .mnc-modal.tiny {
    width: 95%;
  }
  .mnc-modal.small {
    width: 95%;
  }
  .mnc-modal.large {
    width: 95%;
  }
}

@media only screen and (min-width: 768px) {
  .mnc-modal {
    width: 75%;
  }
  .mnc-modal.context {
    width: auto;
  }
  .mnc-modal.mini {
    width: 40%;
  }
  .mnc-modal.tiny {
    width: 60%;
  }
  .mnc-modal.small {
    width: 80%;
  }
  .mnc-modal.large {
    width: 97%;
  }
}

@media only screen and (min-width: 992px) {
  .mnc-modal {
    width: 75%;
  }
  .mnc-modal.context {
    width: auto;
  }
  .mnc-modal.mini {
    width: 40%;
  }
  .mnc-modal.tiny {
    width: 60%;
  }
  .mnc-modal.small {
    width: 80%;
  }
  .mnc-modal.large {
    width: 98%;
  }
}

video.mnc.video {
  width: 100% !important;
  height: auto !important;
}

video.mnc.video.mirrored {
  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg); /* Safari and Chrome */
  -moz-transform:rotateY(180deg); /* Firefox */
}

/* whisperbox */
.whisperbox .input-show-dropdown {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.whisperbox .search-dropdown {
  position: absolute;
  width: calc(100% - 28px);
  z-index: 2;
}

.whisperbox .dropdown-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #f1f1f1;
  border-top: 0;
  border-radius: 3px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.whisperbox .dropdown-ul-li {
  padding: 10px;
  font-size: 16px;
  font-weight: 100;
  background-color: white;
}

.whisperbox .dropdown-ul-li:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
}

.whisperbox .dropdown-ul-li.active {
  background-color: #f1f1f1;
  cursor: pointer;
}

.rows-per-page .ui.dropdown {
  padding: 0.2em 0.3em;
  padding-right: 30px;
  line-height: 1.9em;
  min-height: 20px;
}

.rows-per-page .ui.upward.selection.dropdown .menu {
  width: auto;
  border-bottom-width: 1px!important;
}

.rows-per-page .ui.selection.dropdown .menu {
  width: auto;
  border-top-width: 1px!important;
}

.rows-per-page .ui.compact.dropdown .dropdown.icon {
  padding: 5px;
  padding-top: 10px;
}

.menu .item .icon-span-left {
  float: left;
  width: 25px;
}

.menu .item .icon-span-right {
  float: right;
  width: 20px;
  padding-left: 10px;
}

.ui.menu .dropdown.item .menu, .ui.vertical.menu .dropdown.item .menu {
  min-width: max-content;
}

.ui.compact.vertical.menu .item .menu .item:last-child::before {
  display: none;
}

.widget-menu>.vertical.menu .active.item {
  background: transparent;
  font-weight: 700;
}

.widget-menu>.vertical.menu .opened.item .item {
  padding-left: 20px
}

.widget-menu>.vertical.menu .opened.item .opened.item .item {
  padding-left: 20px
}

.widget-menu>.vertical.menu .opened.item a {
  color: rgba(0,0,0,.5);
  display: block;
}

.widget-menu>.vertical.menu .opened.item a:hover {
  color: rgba(0,0,0,.85);
}

.widget-menu>.vertical.menu .opened.item a.active {
  color: rgba(0,0,0,.85);
}

.mnc-carousel .paging-items {
  position: relative;
  margin: 0;
  top: -10; 
  padding: 0;
}

.mnc-carousel .paging-items li.paging-item {
  list-style-type: none;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.mnc-carousel .paging-items li.paging-item button {
  cursor: pointer;
  background: #2185D0;
  border-radius: 50%;
  border: none;
  padding: 0px;
  width: 14px;
  height: 14px;
}

.mnc-carousel .paging-items li.paging-item.active button {
  width: 24px;
  height: 24px;
}

.mnc-carousel .paging-items li.paging-item button .paging-dot {
  display: inline-block;
  border-radius: 50%;
  width: 0px;
  height: 0px; 
  background: transparent;
}

.mnc-carousel .paging-items li.paging-item.active button .paging-dot {
  background: transparent;
}

.mnc-carousel .prev-button, .mnc-carousel .next-button {
  border: 0;
  background: #2185D0;
  color: white;
  padding: 5px;
  opacity: 1;
  cursor: pointer;
}

.mnc-carousel .prev-button:disabled, .mnc-carousel .next-button:disabled {
  cursor: not-allowed;
  opacity: 0.2;
}

@media only screen and (max-width: 575px) {

  .ui.form.xs-auto .ui.column.stackable.grid>[class*="one wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid>.column.row>[class*="one wide"].column.mobile-no-100,
  .ui.form.xs-auto .ui.stackable.grid>.row>[class*="one wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid>[class*="one wide"].column.mobile-no-100 {
    width: 8.33333333%!important;
  }

  .ui.form.xs-auto .ui.column.stackable.grid>[class*="two wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid>.column.row>[class*="two wide"].column.mobile-no-100,
  .ui.form.xs-auto .ui.stackable.grid>.row>[class*="two wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid>[class*="two wide"].column.mobile-no-100 {
    width: 16.66666667%!important;
  }

  .ui.form.xs-auto.ui.column.stackable.grid>[class*="three wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid>.column.row>[class*="three wide"].column.mobile-no-100,
  .ui.form.xs-auto .ui.stackable.grid>.row>[class*="three wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid>[class*="three wide"].column.mobile-no-100 {
    width: 25%!important;
  }

  .ui.form.xs-auto .ui.column.stackable.grid>[class*="four wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid>.column.row>[class*="four wide"].column.mobile-no-100,
  .ui.form.xs-auto .ui.stackable.grid>.row>[class*="four wide"].column.mobile-no-100, .ui.form.xs-auto.ui.stackable.grid>[class*="four wide"].column.mobile-no-100 {
    width: 33.33333333%!important;
  }

  .ui.form.xs-auto .ui.column.stackable.grid>[class*="five wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid>.column.row>[class*="five wide"].column.mobile-no-100,
  .ui.form.xs-auto .ui.stackable.grid>.row>[class*="five wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid>[class*="five wide"].column.mobile-no-100 {
    width: 41.66666667%!important;
  }

  .ui.form.xs-auto .ui.column.stackable.grid>[class*="six wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid>.column.row>[class*="six wide"].column.mobile-no-100,
  .ui.form.xs-auto .ui.stackable.grid>.row>[class*="six wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid>[class*="six wide"].column.mobile-no-100 {
    width: 50%!important;
  }

  .ui.form.xs-auto .ui.column.stackable.grid>[class*="seven wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid>.column.row>[class*="seven wide"].column.mobile-no-100,
  .ui.form.xs-auto .ui.stackable.grid>.row>[class*="seven wide"].column.mobile-no-100, .ui.form.xs-auto.ui.stackable.grid>[class*="seven wide"].column.mobile-no-100 {
    width: 58.33333333%!important;
  }

  .ui.form.xs-auto .ui.column.stackable.grid > [class*="eight wide"].column.mobile-no-100, .ui.form.xs-auto.ui.stackable.grid > .column.row > [class*="eight wide"].column.mobile-no-100,
  .ui.form.xs-auto .ui.stackable.grid > .row > [class*="eight wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid > [class*="eight wide"].column.mobile-no-100 {
    width: 66.66666667%!important;
  }

  .ui.form.xs-auto .ui.column.stackable.grid > [class*="nine wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid > .column.row > [class*="nine wide"].column.mobile-no-100,
  .ui.form.xs-auto .ui.stackable.grid > .row > [class*="nine wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid > [class*="nine wide"].column.mobile-no-100 {
    width: 75% !important;
  }

  .ui.form.xs-auto .ui.column.stackable.grid > [class*="ten wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid > .column.row > [class*="ten wide"].column.mobile-no-100,
  .ui.form.xs-auto .ui.stackable.grid > .row > [class*="ten wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid > [class*="ten wide"].column.mobile-no-100 {
    width: 83.33333333%!important;
  }

  .ui.form.xs-auto .ui.column.stackable.grid > [class*="eleven wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid > .column.row > [class*="eleven wide"].column.mobile-no-100,
  .ui.form.xs-auto .ui.stackable.grid > .row > [class*="eleven wide"].column.mobile-no-100, .ui.form.xs-auto .ui.stackable.grid > [class*="eleven wide"].column.mobile-no-100 {
    width: 91.66666667%!important;
  }

  .ui.column.grid > .column.no-mobile, .ui.grid > .column.row > .column.no-mobile,
  .ui.grid > .row > .column.no-mobile, .ui.grid > .column.no-mobile {
    display: none;
  }

}

.mnc-file-drop-zone {
  border: 2px dashed #e0e1e2;
  cursor: pointer;
}

.mnc-file-drop-zone.drag {
  border-color: #2185D0;
}

.mnc-file-drop-zone-placeholder {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  text-align: center;
  color: #e0e1e2;
  font-size: 22px;
  padding-left: 10px;
  padding-right: 10px;
}

.mnc-file-drop-zone-placeholder.selected {
  color: #757575;
  font-size: 16px;
}

.mnc-file-drop-zone-placeholder.drag {
  color: #2185D0;
}

.mnc-file-drop-zone-placeholder i.icon, .mnc-file-drop-zone-placeholder i.flag {
  margin-left: 7px;
  margin-right: 7px;
}

.mnc-file-drop-zone-placeholder .progress {
  color:green;
}

.ui.form .column.field.nohelp label.help {
  display: none;
}

.ui.breadcrumb .divider {
  margin: 0 8px;
}

.ui.grid>.row>.ui.segment.column.widget-pillbox {
  padding-top: 0px;
  padding-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
}

.ui.segment.column.widget-pillbox .ui.row.pill-header {
  padding-bottom: 8px;
}

.ui.segment.column.widget-pillbox .ui.label.main-pill {
  border-radius: 4px 0px;
  background-color: #f0f0f0;
  padding-left: 8px;
  padding-right: 8px;
}

.ui.segment.column.widget-pillbox .ui.label.main-pill a {
  padding: 5px;
  border-radius: 4px;
}

.ui.segment.column.widget-pillbox .ui.label.main-pill a:hover {
  background-color: #e0e1e2;
}

.ui.segment.column.widget-pillbox .ui.row.pill-header .pill-header-main-right {
  padding-left: 5px;
}

.ui.segment.column.widget-pillbox .ui.row.pill-header .ui.label.pill {
  margin-top: 3px;
  margin-left: 5px;
  background-color: #f0f0f0;
  border: 1px solid #eaeaea;
}

.ui.segment.column.widget-pillbox .ui.row.pill-header .ui.label.pill:hover {
  background-color: #e0e1e2;
}

.ui.segment.column.widget-pillbox .ui.row.pill-header .ui.label.pill.active, .ui.segment.column.widget-pillbox .ui.row.pill-header .ui.label.pill.active:hover {
  background-color:  transparent;
}

.ui.segment.column.widget-pillbox .ui.label>a {
  color: rgba(0,0,0,.6);
  opacity: 1;
}

.ui.dropdown.colorpalette .menu>.item {
  display: inline-block;
  min-width: 45px;
  padding: 10px!important;
}

.ui.dropdown.colorpalette .text .ui.label {
  border: 1px solid rgba(34,36,38,.15);
  font-size: 5px;
  height: 12px;
  width: 15px;
}

.ui.dropdown.colorpalette .menu>.item .ui.label {
  width: 25px;
  height: 25px;
  border: 1px solid rgba(34,36,38,.15);
}

.ui.dropdown.colorpalette .menu>.item .ui.label.delete-color:before {
  content: "\F00D";
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  position: absolute;
  top: 17px;
  left: 18px;
}

.ui.dropdown .menu .invalid.item, .ui.dropdown .menu .invalid.selected.item, .ui.dropdown .menu .invalid.disabeld.item, 
.ui.search.dropdown>.text.invalid, .ui.dropdown>.text.invalid, .ui.multiple.dropdown>.label.invalid {
  color: red;
}

.ui.dropdown>.text .image, .ui.dropdown>.text img {
  display: inline-block;
  vertical-align: top;
  width: auto;
  margin-top: -.5em;
  margin-bottom: -.5em;
  max-height: 2em;
}

.ui.dropdown .ui.label>a {
  color: #4183c4;
  opacity: 1;
}

.ui.dropdown .ui.label>a:hover, .ui.dropdown .text>a:hover {
  text-decoration: underline;
}

.ui.auto-inline.wide.column {
  width: auto!important;
  padding: 0!important;
  padding-left: 5px!important;
}

.ui.auto-inline.wide.column:first-child {
  margin-left: 11px;
}

.ui.table label.help.thin {
  display: none;
}

span i.icon:first-child, span i.flag:not(.icon):first-child, .ui.header i.flag:not(.icon):first-child, a i.icon:first-child, a i.flag:not(.icon):first-child {
  margin-right: 5px;
}

span i.icon:last-child, span i.flag:not(.icon):last-child, .ui.header i.flag:not(.icon):last-child, a i.icon:last-child, a i.flag:not(.icon):last-child {
  margin-left: 5px;
}

.ui.icon.input>i.flag {
  cursor: default;
  position: absolute;
  text-align: center;
  top: calc(50% - 5.5px);
  right: 0;
  margin: 0;
  width: 2.67142857em;
}

.ui[class*="left icon"].input>i.flag {
  right: auto;
  left: 1px;
}

.ui.header>i.flag:not(.icon):first-child {
  padding-right: 5px;
}

.ui.header>i.flag:not(.icon):last-child {
  padding-left: 5px;
}

.ui.button:not(.icon)>.icon:not(.button):not(.dropdown), .ui.button>.flag:not(.icon) {
  margin: 0 .42857143em 0 .42857143em;
}

.ui.button:not(.icon)>.right.icon:not(.button):not(.dropdown) {
  margin: 0 .42857143em 0 .42857143em;
}

.ui.row.mc-label .ui.header>.content {
  display: table-cell;
  vertical-align: middle;
}

.ui.row.mc-label .ui.header>.content+.icon {
  padding-left: .75rem;
}

.widget-iframe iframe {
  width: 100%;
  border: none;
}

.ui.pagination.menu .active.item {
  padding: 0.1em 0.2em;
}

.ui.pagination.menu .active.item input {
  padding: 0.2em 0.3em;
  line-height: 1.9em;
}

.ui.pagination.menu .rows-per-page.item, .ui.pagination.menu .gap.item {
  padding: 0.1em 0.2em;
}

.ui.center.floated.menu {
  float: none;
}

.ui.grid>.row>.column.widget-pageabletable, .ui.grid>.row>.column.widget-table {
  padding-left: 0px;
}

.widget-pageabletable div.tableWrapper, .widget-table div.tableWrapper {
  position: relative;
  padding-left: 1rem;
}

.mnc-tablemenu.icon {
  position: absolute;
  top: 14px;
  left: 2px;
  font-size: 12px;
  color: #77b2f7;
}

.mnc-tablemenu.icon:hover {
  font-size: 20px;
  border: #77b2f7 1px solid;
  border-radius: 3px;
  background-color: white;
}

.ui.divider.header>.icon {
  margin-left: .5rem;
  margin-right: .5rem;
}

.ui.divider.header>.flag:not(.icon):first-child,  .ui.divider.header>.flag:not(.icon):last-child {
  padding-left: .5rem;
  padding-right: .5rem;
}

@media only screen and (max-width: 767px) {
  .ui.grid .stackable.row .ui.vertical.divider, .ui.stackable.grid .ui.vertical.divider {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    margin: 0;
    padding: 0;
    width: auto;
    height: 50%;
    line-height: 0;
    text-align: center;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    overflow: visible;
  }
  .ui.vertical.divider:after, .ui.vertical.divider:before,
  .ui.stackable.grid .ui.vertical.divider:after, .ui.stackable.grid .ui.vertical.divider:before {
    position: absolute;
    left: 50%;
    content: '';
    z-index: 3;
    border-left: 1px solid rgba(34,36,38,.15);
    border-right: 1px solid rgba(255,255,255,.1);
    width: 0%;
    height: calc(100% - 1rem);
  }
  .ui.vertical.divider:before, .ui.stackable.grid .ui.vertical.divider:before {
    top: -100%;
  }
  .ui.vertical.divider:after, .ui.stackable.grid .ui.vertical.divider:after {
    top: auto;
    bottom: 0;
  }
}

@media only screen and (max-width: 575px) {
  .ui.grid .stackable.row .ui.vertical.divider, .ui.stackable.grid .ui.vertical.divider {
    display: table;
    white-space: nowrap;
    height: auto;
    margin: 1rem 0;
    overflow: hidden;
    line-height: 1;
    text-align: center;
    position: static;
    top: 0;
    left: 0;
    -webkit-transform: none;
    transform: none;
  }
  .ui.grid .stackable.row .ui.vertical.divider:after, .ui.grid .stackable.row .ui.vertical.divider:before, .ui.stackable.grid .ui.vertical.divider:after, .ui.stackable.grid .ui.vertical.divider:before {
    position: static;
    left: 0;
    border-left: none;
    border-right: none;
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    background-repeat: no-repeat;
  }
  .ui.grid .stackable.row .ui.vertical.divider:before, .ui.stackable.grid .ui.vertical.divider:before {
    background-position: right 1em top 50%;
  }
  .ui.grid .stackable.row .ui.vertical.divider:after, .ui.stackable.grid .ui.vertical.divider:after {
    background-position: left 1em top 50%;
  }
  .ui.grid .stackable.row .ui.vertical.divider:after, .ui.grid .stackable.row .ui.vertical.divider:before, .ui.stackable.grid .ui.vertical.divider:after, .ui.stackable.grid .ui.vertical.divider:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC);
  }
}

.widget-videoviewer video {
  width: 100%;
}

.drag-ico, span i.icon.drag-ico:last-child, span i.icon.drag-ico:first-child {
  width: 1.2em!important;
  height: 100%!important;
  cursor: grab;
  color: rgba(0,0,0,0.2);
  margin-left: 0;
  margin-right: 0;
}

.drag-ico:hover, span i.icon.drag-ico:hover {
  color: rgba(0,0,0,0.6);
}

td.selector .drag-ico, th.selector .drag-ico {
  width: 1em!important;
}

th.selector .drag-ico {
  width: 1em!important;
  color: transparent;
  pointer-events: none;
}

.ui.table th.candrop, .ui.sortable.table thead th.sorted.candrop {
  background-color: #D3FFDF;
}

.ui.table th.candrop:before {
  content: "\f063";
  font-family: Icons;
  color: green;
  position: absolute;
  top: -15px;
  left: -5px;
}

.ui.table th.candrop.rightdrop:before {
  right: -5px;
  left: auto;
}

.ui.table th.cantdrop {
  background-color: #ffe3d3;
}

.tippy-tooltip {
  overflow-wrap: break-word;
}

/* FROM APPMODELER DEBUGGER */
.ui.form.appmodeler-debugger .ui.grid>.column:not(.row){
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}
.ui.form.appmodeler-debugger .ui.grid>.row {
  padding-top: 1px !important;
}
.ui.form.appmodeler-debugger div.linkViewInModel {
  margin-top: 44px !important;
}
.ui.form.appmodeler-debugger div.hitbox .hbox.flex-row {
  margin-bottom: 23px;
}
.ui.form.appmodeler-debugger div.hitboxleft .hbox.flex-row {
  margin-bottom: 23px;
  margin-left:-20px;
}
.ui.form.appmodeler-debugger div.elementLabel {
  font-weight: bold;
}
.ui.form.appmodeler-debugger div.treeStructure {
  width:90%;
  margin-left: 2%;
}
.ui.form.appmodeler-debugger .ui.grid>.row>.ui.segment.column {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}
.ui.form.appmodeler-debugger div.configuration {
  margin-top: 0em !important;
  margin-bottom: 0em !important;
}
.ui.form.appmodeler-debugger div.singleCallServer {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
  border: 1px solid !important;
  border-color: rgb(218, 165, 32) !important;
  border-radius: 1px !important;
}
.ui.form.appmodeler-debugger div.singleCallFrontEnd {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
  border: 1px solid !important;
  border-color: rgb(33, 133, 208) !important;
  border-radius: 1px !important;
}
.ui.form.appmodeler-debugger div.detailHeader {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}
.ui.form.appmodeler-debugger div.space20 {
  height: 0px !important;
}
.ui.form.appmodeler-debugger div.ui.bottom.aligned.one.wide.column.field.widget.blue.valued {
  height:10px;
  padding-bottom: 1.9rem !important;
}
.ui.form.appmodeler-debugger div.ui.bottom.aligned.one.wide.column.field.widget.orange.valued {
  height:10px;
  padding-bottom: 1.9rem !important;
}
.tableWrapper.scrollable-horizontal {
  overflow-x: auto;
}
.handy-scroll {
  bottom: 0;
  min-height: 17px;
  overflow: auto;
  position: fixed;
}
.handy-scroll div {
  height: 1px;
  overflow: hidden;
  pointer-events: none;
}
.handy-scroll div:before {
  content: "\A0";
}
.handy-scroll, .handy-scroll div {
  font-size: 1px;
  line-height: 0;
  margin: 0;
  padding: 0;
}
.handy-scroll-hidden {
  bottom: 9999px;
}
.handy-scroll-hidden div:before {
  content: "\A0\A0";
}
.handy-scroll-viewport {
  position: relative;
}
.handy-scroll-body {
  overflow: auto;
}
.handy-scroll-viewport .handy-scroll {
  left: 0;
  position: absolute;
}
.handy-scroll-hoverable .handy-scroll {
  opacity: 0;
  transition: opacity 0.5s ease 0.3s;
}
.handy-scroll-hoverable:hover .handy-scroll {
  opacity: 1;
}
