/* meta */
.meta.row {
  display: flex;
}

.meta.column {
  display: flex;
  flex-direction: column;
}

.meta.grow {
  flex-grow: 1;
}

.meta.vertical.scroll {
  overflow-y: auto;
}

.meta.horizontal.scroll {
  overflow-x: auto;
}

.meta.center-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.meta.container {
  width: 900px;
}

.meta.vertical-center-content {
  display: flex;
  align-items: center;
}

.meta.do-not-shrink {
   flex-shrink: 0;
}

.meta.left-padded {
   padding-left: 1rem;
}

/* menu */
.meta.menu {
  display: flex;
  min-height: 2.85714286em;
  flex-shrink: 0;
  align-items: center;
}

.meta.menu.right {
  margin-left: auto;
}

.meta.menu .meta.menu-item {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.meta.center-items {
   align-items: center;
}

/* pop over */

.meta.popover .meta.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
}

.meta.popover .popover-content {
    position: absolute;
    /*min-width: 160px;*/
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 2;
}

.meta.popover:focus {
  outline: none;
}



/* basic */
.meta.paper {
  background-color: white;
}

.meta.element {
  padding: 5px;
}

.meta.bordered {
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem;
}

.meta.padded {
  padding: .5em 0.1em .5em 1em;
}

.meta.clickable {
  cursor: pointer;
}

.meta.pointable {
  cursor: default;
}

.meta.clickable:focus {
  outline: none;
}

.meta.right-floated {
  margin-left: auto;
}

/* dropdown */
.meta.dropdown {
    position: relative;
    display: inline-block;
}

.meta.dropdown:focus {
  outline: none;
}
.meta.dropdown .dropdown-content {
    display: none;
    position: absolute;
    /*background-color: #f9f9f9;*/
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s linear;
}

.meta.dropdown:hover .dropdown-content {
  opacity: 1;
  display: block;
}

.meta.dropdown .meta.dropdown-item {
  transition: background .1s ease,box-shadow .1s ease,color .1s ease;
  padding: 10px 15px;
  background-color: white;
}

.meta.dropdown .meta.dropdown-item:hover {
  cursor: pointer;
  background-color: rgb(245, 246, 247);
  color: rgba(0,0,0,.95);
}



/* components */
.right.component {
    border-left-color: rgb(230, 230, 230);
    border-left-style: solid;
    border-left-width: 1px;
}

.left.component {
  border-right-color: rgb(230, 230, 230);
  border-right-style: solid;
  border-right-width: 1px;
}

.top.component {
  border-bottom-color: rgb(230, 230, 230);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.bottom.component {
  border-top-color: rgb(230, 230, 230);
  border-top-style: solid;
  border-top-width: 1px;
}




/*******************************
            Container
*******************************/


/* All Sizes */
.meta.container {
  display: block;
  max-width: 100% !important;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .meta.container {
    margin-left: 1em;
    margin-right: 1em;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .meta.container {
    width: 723px;
  }
}

/* Small Monitor */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .meta.container {
    width: 933px;
  }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) {
  .meta.container {
    width: 1127px;
  }
}
